/* Reset default spacing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

.App {}

.wrapper {
  position: fixed;
  top: 0;  
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgb(0, 15, 28);
}

.particles {
  position: absolute;
  width: 100%;
  top: 50px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 1000;
  margin-top: 20px;
  background-color: rgba(13, 13, 13, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header img {
  height: 40px; /* Adjust this value to match your logo size */
  width: auto;
}

.landing {
  position: fixed;
  color: #e1e7e8;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 80px 20px;
}

.landing img {
  width: 28rem;
}

.intro {
  max-width: 800px;
  margin: 0;
  padding: 4rem;
  text-align: center;
  margin-top: -80px;
}

.intro p {
  font-size: 22px;
}

.intro h1 {
  line-height: 3rem;
}

.register {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width:850px) {
  .landing {
    color: #e1e7e8;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    margin-top: 0rem;
  }
  
  .landing img {
    width: 18rem;
  }
  .notify {
    padding-left: 0.7rem;
  }
  .notify a {
    font-size: 0.8rem;
  }
  p {
      font-size: 0.7rem;
  }
  .intro {
    margin-top: 1.4rem; 
  } 
  .intro p {
    font-size: 0.9rem;
    line-height: 1.8rem;
  }
  .intro h1 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .landing{
      color: #e1e7e8;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      margin-top: 0rem;
    }
    .landing img {
      width: 12rem;
    }
    .notify {
      padding-left: 0.7rem;
    }
    .notify a {
      font-size: 0.5rem;
    }
    p {
        font-size: 0.7rem;
    }
    .intro p {
      font-size: 0.7rem;
      line-height: 1.8rem;
    }
    .intro h1 {
      font-size: 1rem;
    }
}

.highlight-text {
  color: #c9c9c9;
  font-weight: bold;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.login-button {
  padding: 8px 24px;
  background-color: transparent;
  color: #c9c9c9;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  text-decoration: none; /* Remove underline from link */
}

.arrow {
  color: #c9c9c9;
  font-size: 48px;
}
