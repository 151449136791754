@import url('https://fonts.googleapis.com/css?family=Lato|Noto+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#01071c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link, a:visited {
  color: #0d72b9;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  outline: 0;
}


h1 {
  font-weight: 700;
}